.explorar-recetas {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .filters {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  

  .filters-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Ajusta el espacio entre elementos */
  }
  
  .filters-container input, .filters-container select, .filters-container .react-select-container {
    width: 200px; /* O cualquier ancho que prefieras */
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .filters-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Ajusta el espacio entre elementos */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en múltiples líneas */
  }
  
  .filters-container input, .filters-container select, .filters-container .react-select-container {
    width: 200px; /* O cualquier ancho que prefieras */
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Cambia el diseño cuando la pantalla es de 600px o menos */
  @media (max-width: 600px) {
    .filters-container {
      flex-direction: column; /* Coloca los elementos uno encima del otro */
    }
  }

  .recetas-grid {
    display: grid;
    gap: 20px;
    margin: 20px;
    grid-template-columns: repeat(3, 1fr); /* 3 elementos por fila por defecto */
  }
  
  /* Cambia el diseño cuando la pantalla es de 600px o menos */
  @media (max-width: 600px) {
    .recetas-grid {
      grid-template-columns: repeat(1, 1fr); /* 1 elemento por fila en pantallas pequeñas */
    }
  }