/* MenuSemanal.css */

.table-container {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Añade esta línea */
}

.table th, .table td {
    border: 1px solid black;
    text-align: center;
    padding: 0 10px;
}

.table th:first-child, .table td:first-child {
    width: 8%; /* Ajusta este valor según tus necesidades */
    white-space: nowrap;
}

.list-item-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
#myListItem.list-item-style {
    border: 1px solid rgb(202, 201, 201); /* Añade un borde */
    border-radius: 10px; /* Añade esquinas redondeadas */
    margin-bottom: 10px; /* Añade un margen inferior para separar las tarjetas */
    padding: 10px; /* Añade un padding para dar espacio al contenido de la tarjeta */
  }
  

  
  .list-item-popup-style {
    padding-left: 35px;
    padding-right: 20px;
    border-bottom: 1px solid rgb(202, 201, 201);
  }

  .list-item-popup-style:first-child {
    border-top: 1px solid rgb(202, 201, 201);
  }

  .popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .popup-content {
    position: relative; /* Add this line */
    background-color: white;
    padding: 20px;
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    height: 70vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
    overflow-y: auto;
  }

  .add-recipe-button{
    margin-top: 10px !important;
  }

  .week-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .day-card {
    flex: 1 0 21%;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.button {
    background-color: #007BFF;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    height: 70vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
    overflow-y: auto;
    border-radius: 10px;
}

  
  .meal-select {
    margin-bottom: 10px;
  }
  .nutrient-info {
    border-top: 1px solid #000;
    margin-top: 10px;
    padding-top: 10px;
  }